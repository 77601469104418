import { SPA } from 'components/SPA'
import { AnonymousDocs } from 'routes/Docs'

export default function () {
  return (
    <SPA>
      <AnonymousDocs />
    </SPA>
  )
}

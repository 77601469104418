import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import CommonContextsWrapper from 'components/Wrappers/CommonContextsWrapper'

import useAuth from '../../contexts/auth'
import { WorkspaceProvider } from '../../components/Workspaces/workspace-context'
import { IntegrationAppProviderReactSDK } from '../Workspaces/Workspace/components/WorkspaceContextsWrapper'
import RenderFullDoc from 'routes/Docs/components/RenderFullDoc'

export function AnonymousDocs() {
  return (
    <CommonContextsWrapper>
      <Routes>
        <Route index element={<Navigate to='overview' replace />} />
        <Route path='*' element={<Docs />} />
      </Routes>
    </CommonContextsWrapper>
  )
}

function Docs() {
  const path = useParams()['*']

  const { self } = useAuth()

  if (!path) {
    return <Navigate to='overview' replace />
  }

  if (self?.workspace) {
    return (
      <WorkspaceProvider wsId={self.workspace?.id}>
        <IntegrationAppProviderReactSDK>
          <RenderFullDoc path={path} />
        </IntegrationAppProviderReactSDK>
      </WorkspaceProvider>
    )
  }

  return <RenderFullDoc path={path} />
}
